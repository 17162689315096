export default (
  <div>
    <p>
      ㈜에스앤씨세인(이하 ‘회사’)은 고객의 개인정보를 보호하기 위하여 항상 최선을 다해 노력하고 있으며, 개인정보보호
      관련 주요 법률인 “개인정보보호법”을 비롯한 모든 개인정보보호 관련 법률 규정을 준수하고 있습니다.
    </p>
    <p>
      ‘회사’는 고객의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원할하게 처리할 수 있도록
      개인정보처리방침을 제정하여 이를 준수하고 있으며, 이를 홈페이지 및 모바일 어플리케이션에 공개하고 있습니다.
    </p>
    <ul>
      <li>
        <h1>1. 총칙</h1>
        <p>
          개인정보란 생존하는 개인에 관한 정보로서 성명, 주민등록번호 등의 사항에 의하여 개인을 식별할 수 있는 정보(당해
          정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를
          말합니다.
        </p>
        <p>
          ‘회사’는 고객의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 동의를 받고, 이용하는 서비스의 형태에 따라
          개인정보를 수집 및 이용, 제공, 파기하고 있습니다. 단, 동의를 거부하는 경우 ‘회사’ 서비스의 전부 또는 일부
          이용이 제한될 수 있습니다.
        </p>
        <p>
          ‘회사’는 관련 법률 및 지침의 변경, 또는 내부 개인정보 운영방침의 변경에 따라 개인정보처리방침을 개정할 수
          있으며, 개정하는 경우 시행일자 등을 부여하여 개정된 내용을 확인할 수 있도록 홈페이지를 통하여 공지합니다.
        </p>
      </li>
      <li>
        <h1>2. 수집하는 개인정보의 항목</h1>
        <p>
          ‘회사’는 다양한 서비스 제공을 위하여 아래와 같은 고객 정보를 수집하고 있습니다. 만 14세 미만 아동은
          법정대리인(부모 등)의 동의를 받아야 회원 가입이 가능하며, 법정대리인의 동의를 얻기 위하여 법정대리인의 성명,
          이메일 등 필요한 최소한의 정보를 요구할 수 있습니다. 이 경우 개인정보의 수집ㆍ이용 또는 제공 목적 및
          법정대리인의 동의가 필요하다는 취지를 아동이 쉽게 이해 할 수 있는 평이한 표현으로 아동에게 고지합니다. 이때,
          수집된 법정대리인의 개인정보는 법정대리인의 동의여부를 확인하는 목적 외의 용도로 이를 이용하거나 제3자에게
          제공되지 않습니다.
        </p>
        <ul>
          <li>
            - 홈페이지
            <br />
            <ul>
              <li>1) 고객상담 필수항목 : 이름, 휴대폰번호, 이메일</li>
              <li>2) 가맹상담/창업설명회신청 필수항목 : 이름, 휴대폰번호, 이메일, 창업희망지역, 점포소유여부</li>
            </ul>
          </li>
          <li>
            - 더벤티 멤버십
            <br />
            <ul>
              <li>
                - 필수항목 : 성명, 휴대폰번호, 생년월일, 성별, 이메일, 닉네임
                <ul>
                  <li>1) 만 14세 미만의 경우 : 법정대리인의 성명, 생년월일, 성별, 휴대폰번호</li>
                  <li>2) 비회원의 경우 : 휴대폰번호</li>
                  <li>3) 교환권, 금액권 선물의 경우 : 수령인 성명, 수령인 휴대폰번호</li>
                </ul>
              </li>
              <li>- 선택항목 : 앱푸쉬 수신동의여부, 위치정보 수집동의여부</li>
              <li>
                - 서비스 이용 또는 사업처리 과정에서 생성/수집되는 정보 : 서비스 이용기록, 접속로그, 쿠키, 접속IP정보,
                결제기록, 이용정지기록, 스탬프 적립기록, 단말기 제조사, 모델명, OS버전정보, push 수신 기록
              </li>
              <li>
                - 마케팅 정보 활용 동의 시 (선택동의) : 휴대폰번호, 생년월일, 성별, 이메일
                <br />
                동의하지 않을 경우 회원가입 시 수집한 개인정보를 광고성 정보 전송 목적으로 활용하지 않습니다
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>3. 개인정보의 처리 목적</h1>
        <p>
          ‘회사’는 수집한 개인정보를 다음의 목적을 위해 활용합니다. 고객이 제공한 모든 정보는 아래 목적에 필요한 용도
          이외로는 사용되지 않으며, 변경될 경우 변경 내용을 고지합니다.
        </p>
        <ul>
          <li>- 회원 서비스 이용에 따른 본인 식별 및 실명 여부, 중복 가입 여부 확인</li>
          <li>- 만 14세 미만 아동에 개인정보 수집에 대한 법정대리인 동의 확인</li>
          <li>- 고객 고충 및 불만에 대한 처리</li>
          <li>- 개인정보 침해 사고에 대한 대비, 분쟁 조정을 위한 기록 보존</li>
          <li>- 신제품 출시 및 이벤트 정보 등의 안내</li>
          <li>- 마케팅, 홍보 등을 위한 고객분석, 편의성 제공</li>
          <li>- 상품 구매에 대한 결제, 신규 서비스 개발 및 맞춤 서비스 제공</li>
          <li>- 통계 데이터 분석에 따른 추천 서비스 제공</li>
          <li>- 창업 상담, 당사 창업관련 정보제공, 창업설명회 참가신청</li>
          <li>- 물품배송 및 불만처리</li>
          <li>- 취소/반품 시 환불 처리</li>
          <li>- 개인소득공제용 현금영수증 신청</li>
        </ul>
      </li>
      <li>
        <h1>4. 개인정보의 처리 및 보유기간</h1>
        <p>
          ‘회사’는 고객의 개인정보를 원칙적으로 개인정보의 수집 및 이용목적이 달성하게 되는 경우 즉시 삭제합니다. 단,
          정보주체로부터 사전에 동의를 받은 경우 또는 상법 등 관련 법령에 의하여 보존할 필요성이 있는 경우 다음의
          경우에는 개인정보를 일정기간 동안 보유 하며 무분별한 회원탈퇴와 재가입으로 인한 피해 최소화, 회원탈퇴 후
          변심에 의한 민원처리 등을 처리하기 위하여 회원탈퇴 후 30일간 회원정보 보유합니다.
        </p>
        <ul>
          <li>
            1) 상법 및 전자상거래 등에서 소비자보호에 관한 법률 등 관련 법령의 규정에 의하여 일정기간 보유해야 할 필요가
            있는 경우(관련 법률이 정한 기간 또는 다음에서 정산 기간 동안 회원 및 거래정보 보유)
            <ul>
              <li>
                - 대금결제 및 재화 등의 공급에 관한 기록 : 5년
                <br />
                보관사유 : 전자상거래등에서의 소비자보호에 관한 법률
              </li>
              <li>
                - 회원의 불만 및 분쟁처리에 관한 기록 : 3년
                <br />
                보관사유 : 전자상거래등에서의 소비자보호에 관한 법률
              </li>
              <li>
                - 표시/광고에 관한 기록 : 6개월
                <br />
                보관사유 : 전자상거래 등에서의 소비자보호에 관한 법률
              </li>
              <li>
                - 신용정보의 수집/처리 및 이용등에 관한 기록 : 3년
                <br />
                보관 사유 : 신용정보의 이용 및 보호에 관한 법률
              </li>
            </ul>
          </li>
          <li>
            2) ㈜에스앤씨세인 및 제휴사가 필요에 의하여 별도로 동의를 득한 경우 : 별도로 동의를 득한 범위 및 이용 기간
            내 보관
          </li>
        </ul>
      </li>
      <li>
        <h1>5. 개인정보 수집 동의 거부</h1>
        <p>
          고객은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은
          없습니다. 단, 필수동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나, 서비스 이용 목적에 따른 서비스
          제공에 제한이 따르게 됩니다.
        </p>
        <p>
          또한, 선택동의 사항에 동의를 거부할 경우, 선택동의 개인정보 항목을 이용하여야 제공받을 수 있는 서비스의 전부
          또는 일부 이용이 제한될 수 있습니다.
        </p>
      </li>
      <li>
        <h1>6. 회원 및 법정대리인의 권리와 그 행사 방법</h1>
        <ul>
          <li>
            1) 회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나
            수정할 수 있으며, ‘회사’의 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입해지(회원탈퇴)를
            요청하실 수 있습니다. 다만, 그러한 경우 원활한 서비스 이용이 불가능 할 수 있습니다.
          </li>
          <li>
            2) 회원 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을,
            가입해지(동의철회)를 위해서는 "회원탈퇴"를 클릭하여 탈퇴가 가능합니다.
          </li>
          <li>3) 혹은 개인정보보호 담당자 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.</li>
          <li>
            4) 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는
            제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리 결과를 제3자에게 지체
            없이 통지하여 정정이 이루어지도록 하겠습니다.
          </li>
          <li>
            5) ‘회사’는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "개인정보의 보유 및
            이용기간"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
          </li>
          <li>
            6) 동의철회, 가입해지(회원탈퇴) 처리 방법
            <ul>
              <li>① 창업문의, 창업설명회 신청/철회 : 더벤티 가맹센터 1661-4553</li>
              <li>② 더벤티 멤버십 탈퇴 : 서비스접속 → 회원로그인 → 내정보 → 회원탈퇴</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>7. 개인정보 열람 및 정정, 수집 및 이용에 대한 동의 철회</h1>
        <p>
          고객(만 14세 미만 아동 회원의 경우 법정대리인)은 언제든지 홈페이지 및 모바일 어플리케이션에 등록되어 있는
          본인의 개인정보(회원정보, 이용·제공 내역, 동의 내역)를 열람하거나 정정하실 수 있으며, 탈퇴를 요청할 수
          있습니다.
        </p>
      </li>
      <li>
        <h1>8. 개인정보 파기절차 및 방법</h1>
        <p>
          고객의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 개인정보 파기절차 및
          방법은 다음과 같습니다.
        </p>
        <ul>
          <li>
            1) 파기절차
            <p>
              고객이 서비스 이용 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의
              서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후
              파기 합니다.
            </p>
            <p>별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>
          </li>
          <li>
            2) 파기방법
            <ul>
              <li>- 종이에 출력된 개인정보 : 분쇄기로 분쇄하거나 소각</li>
              <li>- 전자적 파일 형태로 저장된 개인정보 : 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>9. 개인정보 자동수집 장치설치/운영 및 거부에 관한 사항</h1>
        <p>
          ㈜애스엔씨세인은 고객님의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 운용합니다. 쿠키란 홈페이지를
          운영하는데 이용되는 서버가 고객님의 브라우저에 보내는 아주 작은 텍스트 파일로서 컴퓨터 하드디스크에
          저장됩니다. ㈜에스앤씨세인의 홈페이지는 다음과 같은 목적을 위해 쿠키를 사용합니다.
        </p>
        <ul>
          <li>
            1) 쿠키 등 운영
            <p>
              회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 이용자의 취향과 관심 분야 파악 및 자취 추적, 각종 이벤트
              참여 정도 및 방문 회수 파악 등을 통한 타겟 마케팅 및 개인 맞춤 서비스 제공
            </p>
          </li>
          <li>
            2) 쿠키 등 설치
            <p>
              귀하는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 귀하는 웹브라우저에서 옵션을 설정함으로써 모든
              쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.
            </p>
          </li>
          <li>
            3) 쿠키 설정 거부 방법
            <p>
              쿠키 설정을 거부하는 방법으로는 고객님이 사용하시는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를
              허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            </p>
            <p>(인터넷 익스플로어의 경우: 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보에서 수정)</p>
            <p>단, 귀하께서 쿠키 설치를 거부하였을 경우 쿠키를 이용한 서비스 제공에 어려움이 있을 수 있습니다.</p>
          </li>
        </ul>
      </li>
      <li>
        <h1>10. 개인정보 처리위탁</h1>
        <p>
          ㈜에스앤씨세인(이하 ‘회사’)은 수집된 개인정보의 처리 및 관리 등의 업무를 스스로 수행함을 원칙으로 하나, 원활한
          업무 처리를 위해 필요한 경우 업무의 일부 또는 전부를 ‘회사’가 선정한 업체에 위탁할 수 있습니다. 위탁업체가
          추가 또는 변경되는 경우에 이를 고객에게 고지합니다. ‘회사’는 위탁계약 체결 시 개인정보보호법 제 26조, 제
          39조의12에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적⋅관리적 보호조치, 재위탁 제한, 수탁자에 대한
          관리⋅감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를
          감독하고 있습니다.
        </p>
        <p>현재 ‘회사’의 개인정보처리 수탁자와 그 업무의 내용은 다음과 같습니다.</p>
        <ul>
          <li>
            1) 엔에이치엔㈜
            <ul>
              <li>
                - 위탁업무내용 : 개인정보 식별 및 중복가입 여부 확인, 더벤티 멤버십 어플리케이션 운영을 위한 서버 호스팅
              </li>
              <li>- 보유 및 이용기간 : 회원 탈퇴 시 혹은 위탁계약 종료 시까지</li>
            </ul>
          </li>
          <li>
            2) ㈜엔에이치엔페이코
            <ul>
              <li>
                - 위탁업무내용 : 개인정보 보관 및 유지, 스탬프 적립 및 사용, 멤버십 관련 서비스, 마케팅 정보 발송 (PUSH,
                SMS, LMS, E-mail), 카카오톡 비즈메세지 발송 대행
              </li>
              <li>- 보유 및 이용기간 : 회원 탈퇴 후 30일 혹은 위탁계약 종료 시까지</li>
            </ul>
          </li>
          <li>
            3) ㈜NHN한국사이버결제
            <ul>
              <li>- 위탁업무내용 : 실명인증, 본인확인, SMS 전송</li>
              <li>- 보유 및 이용기간 : 회원 탈퇴 시 혹은 위탁계약 종료 시까지</li>
            </ul>
          </li>
          <li>
            4) ㈜플라이그라운드
            <ul>
              <li>- 위탁업무내용 : 앱 운영 서비스</li>
              <li>- 보유 및 이용기간 : 회원 탈퇴 후 30일 혹은 위탁계약 종료 시까지</li>
            </ul>
          </li>
          <li>
            5) ㈜쿠프마케팅
            <ul>
              <li>- 위탁업무내용 : 모바일상품권(교환권/금액권)의 구매 및 사용</li>
              <li>- 보유 및 이용기간 : 회원 탈퇴 후 30일 혹은 위탁계약 종료 시까지</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>11. 개인정보보호 담당자의 연락처</h1>
        <p>
          ㈜애스엔씨세인은 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
          개인정보보호 담당자를 지정하고 있습니다.
        </p>
        <ul>
          <li>
            [개인정보보호책임자]
            <ul>
              <li>* 성명 : 김종석</li>
              <li>* 부서 : HR팀</li>
              <li>* 직위 : 팀장</li>
              <li>* 전자메일 : jskim@theventi.co.kr</li>
              <li>* 전화번호 : 070-4858-2939</li>
              <li>* 팩스번호 : 070-5099-9059</li>
            </ul>
          </li>
          <br />
          <li>
            [개인정보보호담당자]
            <ul>
              <li>* 성명 : 서예림</li>
              <li>* 부서 : 제휴마케팅팀</li>
              <li>* 직위 : 대리</li>
              <li>* 전자메일 : yrseo@theventi.co.kr</li>
              <li>* 전화번호 : 070-4858-3393</li>
              <li>* 팩스번호 : 070-5099-9059</li>
            </ul>
          </li>
          <br />
          <li>
            [개인정보보호담당부서]
            <ul>
              <li>* 부서명 : HR팀</li>
              <li>* 직위 : 팀장</li>
              <li>* 전자메일 : office@theventi.co.kr</li>
              <li>* 전화번호 : 1644-0513</li>
              <li>* 팩스번호 : 070-5099-9059</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>12. 의견수렴 및 불만처리</h1>
        <p>
          고객님께서는 ㈜에스앤씨세인의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원을 개인정보보호 담당자
          혹은 담당부서로 신고하실 수 있습니다. 당사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
        </p>
        <p>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</p>
        <ul>
          <li>
            - 개인정보침해신고센터
            <ul>
              <li>- 전화 : (국번없이) 118</li>
              <li>- URL : http://privacy.kisa.or.kr</li>
            </ul>
          </li>
          <li>
            - 개인정보분쟁조정위원회
            <ul>
              <li>- 전화 : 02-2100-2499</li>
              <li>- URL : http://www.kopico.go.kr</li>
            </ul>
          </li>
          <li>
            - 대검찰청 사이버수사과
            <ul>
              <li>- 전화 : (국번없이) 1301</li>
              <li>- URL : http://www.spo.go.kr</li>
            </ul>
          </li>
          <li>
            - 경찰청 사이버안전국
            <ul>
              <li>- 전화 : (국번없이) 182</li>
              <li>- URL : http://cyberbureau.pulice.go.kr</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>13. 개인정보를 위한 기술적. 관리적 대책</h1>
        <p>
          ㈜에스앤씨세인(이하 ‘회사’)은 개인정보를 처리함에 있어 도난, 분실, 변조, 유출 또는 훼손되지 않도록 안전성
          확보를 위하여 다음과 같은 기술적, 관리적 대책을 강구하고 있습니다.
        </p>
        <ul>
          <li>
            (1) 기술적 대책
            <ul>
              <li>
                1) 개인정보는 그 종류에 따라 관련 법령이 요구하는 수준의 암호화 저장 또는 전송 시 암호화 기술 적용하여
                관리합니다.
              </li>
              <li>
                2) 백신프로그램을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있으며,
                백신프로그램을 주기적으로 업데이트하고 갑작스러운 바이러스 출현 시에 백신이 나오는 즉시 이를
                제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
              </li>
              <li>
                3) 암호알고리즘을 이용하여 네트워크상 개인정보를 안전하게 전송하는 보안장치(SSL 등)를 채택하고 있습니다.
              </li>
              <li>
                4) 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에
                만전을 기하고 있습니다.
              </li>
            </ul>
          </li>
          <li>
            (2) 관리적 대책
            <ul>
              <li>1) ‘회사’는 개인정보 보호에 관한 내부관리계획을 수립, 시행하고 있습니다.</li>
              <li>
                2) ‘회사’는 개인정보에 대한 접근권한을 필요 · 최소한의 인원으로 제한하고 있습니다. 그 최소한의 인원에
                해당하는 자는 다음과 같습니다.
                <ul>
                  <li>① 고객을 직접 상대하여 상담하거나, CRM(마케팅 등) 업무를 수행하는 자</li>
                  <li>② 개인정보처리책임자 및 그 담당자 등 개인정보관리업무를 수행하는 자</li>
                  <li>③ 기타 업무상 개인정보의 처리가 불가피한 자</li>
                </ul>
              </li>
              <li>
                3) ‘회사’는 개인정보를 처리하는 직원을 대상으로 보안기술 습득 및 개인정보 보호의무 등에 관해 정기적인
                사내 교육 및 외부 위탁 교육을 실시합니다.
              </li>
              <li>
                4) ‘회사’는 직원 입사 시 보안서약서를 통하여 사람에 의한 정보유출을 사전에 방지하고 개인정보처리방침에
                대한 이행사항 및 직원의 준수 여부를 감사하기 위한 내부절차를 마련하고 있습니다.
              </li>
              <li>
                5) 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이루어지도록 하며 입사 및 퇴사 후
                개인정보 사고에 관한 책임을 명확히 합니다.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>14. 시행일자</h1>
        <p>시행일 : 2023년 11월 06일</p>
      </li>
    </ul>
  </div>
);
