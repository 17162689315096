export default (
  <div>
    <ul>
      <li>
        <h1>제 1조 (목적)</h1>
        <p>
          본 약관은 ㈜에스씨앤세인(이하 ‘회사’ 또는 ‘더벤티’이라고 함)가 제공하는 멤버십 서비스(이하 ‘더벤티
          멤버십’이라고 함)의 이용과 관련하여 필요한 이용 조건 및 절차, ’회사’와 ‘회원’간의 권리, 의무 등 기본적인
          사항을 규정하는 것을 목적으로 합니다.
        </p>
      </li>
      <li>
        <h1>제 2조 (용어의 정의)</h1>
        <p>이 약관에서 사용하는 용어는 다음 각 호와 같이 정의합니다.</p>
        <ul>
          <li>
            1. ‘더벤티 멤버십’이란 ‘스탬프’ 적립, ‘쿠폰’의 발행 및 사용, 선물하기, 더벤티오더, 더벤티배달 등 ‘회사’가
            ‘회원’에게 제공하는 멤버십 서비스를 통칭하여 말합니다.
          </li>
          <li>
            2. ‘회원’이란 이 약관에 따라 더벤티 멤버십 모바일 어플리케이션(이하 ‘앱’이라 합니다)을 통해 ‘회사’와
            이용계약을 체결하고, 개인정보의 수집∙활용 기타 사항들에 동의함으로써 ‘더벤티 멤버십’ 서비스를 이용하는
            고객을 말합니다.
          </li>
          <li>
            3. ‘매장’이란 ‘회사’가 직영으로 운영하는 모든 더벤티 매장과 ‘회사’와 계약하여 더벤티 브랜드를 이용하는 모든
            가맹점을 말합니다.
          </li>
          <li>
            4. ‘스탬프’란 더벤티 ‘매장’에서 일부 음료를 제외한 제조 음료 구매 시 적립되며, 스탬프 적립 정책에 따라 일정
            수량 적립시 ‘쿠폰’의 발행이 가능하도록 제공되는 서비스를 말합니다.
          </li>
          <li>
            5. ‘쿠폰’이란 ‘더벤티 멤버십’ 서비스를 이용하여 발행된 모바일 쿠폰(상품권)의 형태로, ‘회원’에게 제공되거나
            직접 구매를 통해 수령하여 ‘매장’에 제시하는 경우, ‘매장’에서 판매하는 제품 및 상품(이하 통칭하여 ‘상품’이라
            함)으로 교환할 수 있는 권리가 있는 전자적 정보를 말하며, 발급 형태에 따라 다음과 같이 구분됩니다.
            <ul>
              <li>
                가) 멤버십쿠폰 : ‘더벤티 멤버십’의 ‘스탬프’ 적립을 통해 발급되거나 ‘회사’와 ‘매장’이 제공하는 이벤트
                쿠폰
              </li>

              <li>
                나) 모바일상품권 : ‘더벤티 멤버십’의 ‘선물하기’ 기능을 이용하여 ‘회원’이 구매 혹은 선물한 교환권과
                금액권
              </li>
            </ul>
          </li>
          <li>
            6. ‘금액권’이란 ‘회원’이 ‘선물하기’에서 일정금액을 지불하고 구매하여 ‘회원’이 ‘매장’에서 ‘상품’ 구매 시
            현금처럼 이용할 수 있도록 한 모바일 상품권을 말합니다.
          </li>
          <li>
            7. ‘부정 적립 및 이용’이란 ‘회사’가 예정하지 않은 방법에 따른 부정적립 및 부정이용을 의미합니다.
            <ul>
              <li>
                가) 부정적립 : ‘회원’이 ‘매장’에서 ‘상품’을 구매 또는 이용하지 않았음에도 불구하고 정당한 이유 없이 당해
                ‘회원’에게 ‘스탬프’가 적립되거나 해당 ‘회원’이 구매 또는 이용한 내용보다 많은 ‘스탬프’가 적립된 경우를
                말합니다.
              </li>
              <li>
                나) 부정이용 : 다른 ‘회원’이 보유하고 있던 ‘스탬프’, ‘쿠폰’ 혹은 ‘교환권, ‘금액권’ 등을 동의 없이 임의로
                사용한 경우를 말합니다.
              </li>
            </ul>
          </li>
          <li>
            8. ‘선물하기’란 ‘더벤티 멤버십’에서 ‘교환권’ 및 ‘금액권’을 수신자의 휴대폰 번호를 이용하여 수신자에게
            선물하는 행위를 말합니다. 수신자에게는 문자메시지 형태로 선물이 전달됩니다.
          </li>
          <li>
            9. ‘결제’란 신용/체크카드, 모바일상품권, 휴대폰 소액결제, 간편결제, 등 기타 ‘회사’가 지정한 방법으로
            유료서비스의 대가를 지불하는 행위를 말합니다.
          </li>
          <li>
            10. ‘교환’이란 ‘회원’이 보유한 ‘쿠폰’을 ‘매장’에 제시하여 ‘쿠폰’에 명시된 ’상품’을 제공받는 행위를 말합니다.
          </li>
          <li>
            11. ‘더벤티오더’란 ‘회원’이 ‘매장’에 직접 방문함이 없이 ‘더벤티 멤버십’을 통해 ‘상품’을 선택하고
            ‘결제’함으로써 빠르게 주문할 수 있는 전자상거래 서비스를 말합니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 3 조 (약관의 게시와 개정)</h1>
        <ul>
          <li>① "회사"는 이 약관의 내용을 "회원"이 쉽게 알 수 있도록 ‘더벤티 멤버십’ 초기 화면에 게시합니다.</li>
          <li>
            ② "회사"는 "약관의 규제에 관한 법률", "정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 "정보통신망법")"
            등 관련법을 위배하지 않는 범위에서 이 약관 및 ‘쿠폰’의 이용, ‘스탬프’의 적립조건 기타 서비스의 이용조건이나
            내용 등을 개정할 수 있습니다.
          </li>
          <li>
            ③ "회사"가 전항에 따른 개정을 할 경우에는 개정된 내용을 적용하고자 하는 날 14일 전부터 적용일자 전일까지
            개정사실과 개정사유, 개정내용 등을 ‘홈페이지’ 또는 ‘앱’에 공지합니다.
          </li>
          <li>
            ④ 개별고지가 필요한 상황에는 ‘회원’이 ‘회사’에 가장 최근에 제공한 전자우편 주소 혹은 휴대폰번호를 활용하며,
            이 경우 ‘회사’는 적법한 고지를 완료한 것으로 봅니다.
          </li>
          <li>
            ⑤ ‘회사’가 제3항과 4항에 따라 공지 또는 고지한 개정사항이 적용된 이후에도 ‘회원’이 30일간 명시적인 거부의
            의사표시 없이 계속적으로 서비스를 이용하는 경우에는 ‘회원’이 개정내용에 동의한 것으로 간주됩니다. 만약
            ‘회원’이 본 조에 따라 개정된 내용의 적용에 동의하지 않는 경우 ‘회원’은 이용계약을 해지함으로써 개정된 내용의
            적용을 배제할 수 있습니다.
          </li>
          <li>⑥ 본 조에 의하여 개정된 약관은 원칙적으로 그 효력 발생일로부터 유효합니다.</li>
        </ul>
      </li>
      <li>
        <h1>제 4 조 (약관의 해석)</h1>
        <ul>
          <li>
            ① ‘회사’는 필요한 경우 특정 서비스에 관하여 별도의 이용약관 및 정책(이하 “개별약관”이라 합니다)을 둘 수
            있으며, 해당 내용이 이 약관과 상충할 경우에는 “개별약관”이 우선하여 적용됩니다.
          </li>
          <li>② 이 약관에서 정하지 아니한 사항이나 해석에 대해서는 관계법령 또는 상관례에 따릅니다.</li>
          <li>
            ③ 본 약관과 관련하여 회사의 정책 변경, 법령의 제, 개정, 공공기관의 고시나 지침 등에 따라 회사가 홈페이지의
            공지사항 게시판 등을 통해 공지하는 내용도 이용계약의 일부를 구성합니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 5 조 (회원가입)</h1>
        <ul>
          <li>
            ① ‘회원’이 되고자 하는 고객은 회원 가입 신청서에 정해진 사항을 기입한 후, 이 약관과 ‘개인정보 수집∙활용
            동의’ 기타 동의가 필요한 사항에 동의함으로써 회원가입을 신청합니다. 단, 만 14세 미만의 경우, 법정 대리인의
            동의를 받아 가입신청을 하여야 하며, 다만 이 경우 관련 법령이나 ‘회사’의 정책에 따라 법정대리인의 동의여부와
            관계없이 가입을 제한하거나 일부 서비스 제공이 불가할 수 있습니다.
          </li>
          <li>
            ② 고객으로부터 회원가입 신청이 있는 경우, ‘회사’는 자체 기준에 따른 심사를 거친 후 고객에게 ‘회원’ 자격을
            부여 할 수 있으며 ‘회원’ 자격이 부여된 고객은 당사로부터 가입 완료 고지를 받은 시점부터 회원으로서의 지위를
            취득합니다.
          </li>
          <li>
            ③ ‘회사’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나 사후에 이용계약을 해지할 수 있습니다.
            <ul>
              <li>1. 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
              <li>2. 실명이 아니거나 타인의 명의를 이용한 경우. 단, ‘등록회원’에 한함</li>
              <li>3. 허위의 정보를 기재하거나, ‘회사’가 제시하는 내용을 기재하지 않은 경우</li>
              <li>
                4. 기타 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 ‘회사’가 정한 제반 사항을 위반하며 신청하는
                경우
              </li>
            </ul>
          </li>
          <li>
            ④ 제1항에 따른 가입신청에 있어 ‘회사’는 ‘회원’의 종류에 따라 전문기관을 통한 실명확인 및 본인인증을 요청할
            수 있습니다.
          </li>
          <li>
            ⑤ ‘회사’는 서비스관련설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우에는 승낙을 유보할 수
            있습니다.
          </li>
          <li>
            ⑥ ‘회사’는 ‘회원’에 대해 회사정책에 따라 종류별, 등급별로 구분하여 서비스 제공에 차등을 둘 수 있습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 6 조 (회원정보의 변경)</h1>
        <ul>
          <li>
            ① ‘회원’은 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 생년월일,
            성별 등은 수정이 불가능합니다.
          </li>
          <li>
            ② ‘회원’은 가입신청 시 기재한 사항이 변경되었을 경우 회원정보 수정을 통해 ‘회사’에 대하여 그 변경사항을
            알려야 하며, 이를 알리지 않아 발생한 불이익에 대하여 ‘회사’는 책임지지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 7 조 (개인정보보호 의무)</h1>
        <p>
          ‘회사"는 "정보통신망법" 등 관계 법령이 정하는 바에 따라 ‘회원’의 개인정보를 보호하기 위해 노력합니다.
          개인정보의 보호 및 사용에 대해서는 관련법령 및 ‘회사’의 개인정보처리방침이 적용됩니다.
        </p>
      </li>
      <li>
        <h1>제 8 조 (‘회원’의 개인정보 관리의무)</h1>
        <ul>
          <li>
            ① ‘회원’의 이메일, 휴대전화번호, 기타 ‘더벤티 멤버십’ 서비스 이용을 위해 필요한 정보들에 관한 관리책임은
            ‘회원’에게 있으며, 이를 제3자가 이용하도록 하여서는 안 됩니다.
          </li>
          <li>
            ② ‘회사’는 ‘회원’의 개인정보 유출 우려가 있는 경우, 해당 ‘회원’의 ‘더벤티 멤버십’ 서비스 이용을 제한할 수
            있습니다.
          </li>
          <li>
            ③ ‘회원’은 자신의 정보가 도용되거나 제3자가 사용하고 있음을 인지한 경우 이를 통해 ‘더벤티 멤버십’의 서비스가
            부정하게 이용될 가능성이 있는 경우에는 이를 즉시 ‘회사’에 통지하고 ‘회사’의 안내에 따라야 합니다.
          </li>
          <li>
            ④ ‘회원’이 ‘회사’에 제3항 사실을 통지하지 않거나, 통지한 경우에도 ‘회사’의 안내에 따르지 않아 발생한 불이익
            및 ‘회원’의 개인정보 관리소홀 기타 ‘회사’의 귀책사유 없이 제3자가 부당하게 ‘더벤티 멤버십’를 이용함으로써
            발생된 불이익에 대하여 ‘회사’는 책임지지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 9 조 (‘회원’에 대한 통지)</h1>
        <ul>
          <li>
            ① ‘회사’가 ‘회원’에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한 ‘더벤티 멤버십’ ‘앱’ 내 푸쉬 알림
            등으로 할 수 있습니다.
          </li>
          <li>
            ② ‘회사’는 ‘회원’ 전체에 대한 통지의 경우 7일 이상 ‘더벤티 멤버십’ ‘앱’ 혹은 ‘홈페이지’의 게시판에
            게시함으로써 제1항의 통지에 갈음할 수 있습니다.
          </li>
          <li>
            ③ ‘회사’는 ‘회원’이 ‘더벤티 멤버십’ 서비스 이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 이메일,
            문자메시지 등의 방법으로 ‘회원’에게 제공할 수 있습니다. 단, ‘회원’은 관련법령에 따른 거래관련 정보 및
            고객문의, 휴면회원 전환 등을 제외하고는 언제든지 푸쉬 알림 및 이메일, 문자메시지에 대해서 수신 거절을 할 수
            있습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 10 조 (‘회원’의 의무)</h1>
        <ul>
          <li>
            ① ‘회원’은 관계법령, 이 약관에서 규정하는 사항과 이용안내 또는 ‘더벤티 멤버십’과 관련한 공지사항, 기타
            ‘회사’가 통지하는 사항 등을 준수하여야 하며, ‘회사’의 업무에 방해되는 행위를 하여서는 안됩니다.
          </li>
          <li>
            ② ‘회원’은 ‘회사’가 제공한 ‘스탬프’ 및 ‘쿠폰’ 등을 다른 ‘회원’에게 재판매하거나 기타 이를 활용하여 영업
            활동을 할 수 없으며, 이를 위반할 경우 제공받았던 혜택을 반환하여야 합니다.
          </li>
          <li>
            ③ ‘회원’은 다음 각 호의 행위를 하여서는 안됩니다.
            <ul>
              <li>1. 신청 또는 변경시 허위내용의 등록</li>
              <li>2. 타인의 정보도용</li>
              <li>3. ‘회사’가 게시한 정보의 변경</li>
              <li>4. ‘회사’와 기타 제3자의 저작권 등 지적재산권에 대한 침해</li>
              <li>5. ‘회사’ 및 기타 제3자의 명예를 훼손시키거나 업무를 방해하는 행위</li>
              <li>6. ‘회사’의 동이 없이 영리를 목적으로 ‘더벤티 멤버십’ 서비스를 사용하는 행위</li>
              <li>7. 기타 불법적이거나 부당한 행위</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>제 11 조 (‘회원’ 탈퇴와 ‘스탬프’의 소멸 처리)</h1>
        <ul>
          <li>
            ① ‘회원’은 언제든지 ‘더벤티 멤버십’를 통해 ‘회원’ 탈퇴를 요청할 수 있으며, ‘회사’는 ‘회원’의 요청에 따라
            조속히 ‘회원’ 탈퇴에 필요한 제반 절차를 수행합니다.
          </li>
          <li>
            ② ‘회원’ 탈퇴 시, 탈퇴 요청일 현재까지 보유한 ‘스탬프’ 및 ‘쿠폰’ 등 ‘더벤티 멤버십’ 의 ‘회원’으로서 보유하던
            모든 권리는 자동으로 소멸되며 재가입하더라도 복원되지 않습니다. 보유하고 있는 ‘교환권’ 및 잔액이 남아있는
            ‘금액권’의 경우 회원정보에 등록된 휴대폰번호를 기준으로 문자메시지가 자동 발송되며, 이후 미사용 혹은
            환불절차 미진행으로 인하여 발생된 손해에 대해 ‘회사’는 책임을 부담하지 않습니다.
          </li>
          <li>
            ③ ‘회사’는 부정한 목적의 반복적인 탈퇴와 재가입을 막기 위하여, 탈퇴 회원의 재가입을 30일간제한하고 있습니다.
            다만, 고객 어플 상에서의 오류 등의 특이사항이 있을 경우 고객센터에서 재가입 기간 제한을 해제할 수 있습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 12 조 (‘회원’ 자격 상실)</h1>
        <ul>
          <li>
            ① ‘회원’이 다음 각 호의 사유에 해당하는 경우, ‘회사’는 당해 ‘회원’에 대한 통보로써 ‘회원’의 자격을 즉시
            상실시킬 수 있습니다.
            <ul>
              <li>
                1. ‘스탬프’나 ‘쿠폰’의 ‘부정 적립 및 이용’이나 ‘교환권’, 금액권’의 ‘부정 등록 및 이용’ 등 ‘더벤티
                멤버십’ 서비스를 ‘회사’가 예정한 방법 또는 목적을 벗어나 이용한 경우
              </li>
              <li>2. 다른 ‘회원’의 서비스 이용을 방해하거나 그 정보를 도용하는 경우</li>
              <li>3. ‘회사’ 또는 다른 ‘회원’의 명예를 훼손하거나 모욕하는 경우 기타 권리를 침해하는 경우</li>
              <li>4. 회원이 사망한 경우</li>
              <li>5. 이 약관 및 관계법령이 금지하거나 사회상규에 반하는 행위를 하는 경우</li>
              <li>6. 최종 서비스 이용일로부터 1년간 이용 내역이 없을 경우(“정보통신망법” 기타 관련 법령에 근거함)</li>
              <li>7. 기타 ‘더벤티 멤버십’의 정상적인 운영을 방해한 경우</li>
            </ul>
          </li>
          <li>
            ② 제11조 제2항 및 동조 제3항은 전항에 따라 ‘회원’ 자격이 상실된 경우에도 준용됩니다. 단, 제11조 제2항 후문은
            ‘회원’이 적법하게 보유한 것이라고 인정되는 경우에 한하여 준용됩니다.
          </li>
          <li>
            ③ 전항에도 불구하고 제1항 제4호로 ‘회원’의 자격이 상실된 경우, ‘회원’이 보유하고 있던 모든 권리는 소멸하며
            상속되지 않습니다.
          </li>
          <li>
            ④ 제1항 각 호의 경우 ‘회사’는 ‘회원’의 자격을 즉시 상실시키지 않고 해당 ‘회원’에 대한 ‘더벤티 멤버십’ 서비스
            제공을 일시 중단시킬 수 있습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 13 조 (권리의 귀속)</h1>
        <p>‘더벤티 멤버십’에 대한 저작권 및 지식재산권 기타 권리는 ‘회사’에 귀속됩니다.</p>
      </li>
      <li>
        <h1>제 14 조 (‘더벤티 멤버십’ 서비스의 중단)</h1>
        <ul>
          <li>
            ① ‘회사’는 다음 각 호의 사유가 발생한 경우 ‘더벤티 멤버십’ 서비스 이용을 일시적으로 중단할 수 있습니다.
            <ul>
              <li>1. ‘더벤티 멤버십’ 서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</li>
              <li>2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
              <li>3. 기기∙기술적 결함이나 네트워크의 오류 및 장애가 발생한 경우</li>
              <li>4. 국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 경우</li>
              <li>5. 기타 중대한 사유로 인하여 ‘회사’가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</li>
            </ul>
          </li>
          <li>
            ② ‘회사’는 전항의 규정에 의하여 ‘더벤티 멤버십’ 서비스의 이용을 중단하는 때에는 그 사유 및 중단기간 등을
            ‘회원’에게 공지합니다. 다만, ‘회사’가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수
            있습니다.
          </li>
          <li>
            ③ ‘회사’는 ‘더벤티 멤버십’ 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검시간은 ‘회사’가
            공지한 바에 따릅니다.
          </li>
          <li>
            ④ 제1항에 따른 서비스 제한 또는 중지로 인하여 일시적으로 ‘스탬프’를 적립하지 못한 경우는 서비스 이용이
            정상화되면 추후 적립이 가능합니다. 다만 영수증 등 기타 ‘회사’가 ‘스탬프’ 적립대상인지 여부를 판단할 수 있는
            증빙을 보유하고 있는 경우에 한합니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 15 조 (‘더벤티 멤버십’ 서비스의 변경)</h1>
        <ul>
          <li>
            ① ‘회사’는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고 있는 전부 또는 일부 ‘더벤티
            멤버십’ 서비스를 변경할 수 있습니다.
          </li>
          <li>
            ② 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우에는 변경사유, 변경될 서비스의 내용 및 제공일자
            등은 그 변경 전에 ‘홈페이지’ 또는 ‘앱’을 통해 고지합니다.
          </li>
          <li>
            ③ ‘회사’는 ‘더벤티 멤버십’ 서비스 중 ‘스탬프’ 적립 및 ‘스탬프쿠폰’ 발행 및 사용 등 무료로 제공되는 서비스의
            일부 또는 전부를 ‘회사’의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 관련법에 특별한
            규정이 없는 한 ‘회원’에게 별도의 보상을 하지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 16 조 (책임제한)</h1>
        <ul>
          <li>
            ① ‘회사’는 천재지변 또는 이에 준하는 불가항력으로 인하여 ‘더벤티 멤버십’ 서비스를 제공할 수 없는 경우에는
            서비스 제공에 관한 책임이 면제됩니다.
          </li>
          <li>
            ② ‘회사’는 ‘회원’의 귀책사유로 인한 ‘더벤티 멤버십’ 서비스 이용의 장애에 대하여는 책임을 지지 않습니다.
          </li>
          <li>
            ③ ‘회사’는 ‘회원’이 ‘더벤티 멤버십’ 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에
            관하여는 책임을 지지 않습니다.
          </li>
          <li>
            ④ ‘회사’는 ‘회원’간 또는 ‘회원’과 제3자 상호간에 ‘더벤티 멤버십’ 서비스를 매개로 하여 거래 등을 한 경우에는
            그로 인해 발생되는 문제에 대해 책임을 지지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 17 조 (분쟁 해결)</h1>
        <ul>
          <li>
            ① ‘회사’는 ‘회원’에게 손해가 발생하거나 ‘회사’와 ‘회원’간 분쟁이 발생하는 경우, 손해배상 등 분쟁처리를 위해
            다음과 같이 고객센터를 설치, 운영합니다.
            <ul>
              <li>1. 더벤티 멤버십 고객센터 : 1644-0513</li>
              <li>2. 선물하기 고객센터(다우기술) : 1599-3785</li>
            </ul>
          </li>
          <li>
            ② ‘회사’는 ‘회원’이 제기한 의견을 신속히 처리합니다. 단, 신속한 처리가 곤란한 경우에는 ‘회원’에게 그 사유와
            처리일정을 즉시 통보합니다.
          </li>
          <li>
            ③ ‘회사’와 ‘회원’간에 발생한 분쟁이 원만히 해결되지 않는 경우, 관련 법령에 따라 '소비자보호법'에 따른
            한국소비자원의 소비자분쟁조정위원회를 통해 조정신청이 가능합니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 18 조 (관할 법원)</h1>
        <ul>
          <li>① ‘회사’와 ‘회원’ 간의 분쟁 및 소송은 대한민국법을 준거법으로 합니다.</li>
          <li>② 이 약관과 관련한 제반 분쟁 및 소송은 민사소송법상의 관할 법원을 제1심 관할 법원으로 합니다.</li>
          <li>
            ③ 해외에 주소나 거소가 있는 ‘회원’의 경우 ‘회사’와 ‘회원’간 발생한 분쟁에 관한 소송은 전항에도 불구하고
            대한민국 서울중앙지방법원을 관할법원으로 합니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 19조 (회원의 종류 및 등급)</h1>
        <ul>
          <li>
            ① ‘회원’은 가입형태에 따라 다음과 같이 나뉘어집니다.
            <ul>
              <li>
                1. 비회원 : ‘앱’을 통한 회원가입 없이, 휴대폰번호를 이용하여 ‘스탬프’ 적립을 1회 이상 한 고객으로,
                준회원은 ‘스탬프’의 적립만 가능하므로, 적립된 ‘스탬프’의 확인 및 ‘스탬프쿠폰’의 발행 및 사용 등을 하기
                위해서는 ‘회원’으로 가입을 하여야 합니다.
              </li>
              <li>
                2. 회원 : ‘앱’을 통한 가입 후 ‘회사’가 제공하는 방법에 따른 실명인증 절차를 거쳐 본인임이 확인된
                고객으로서, 이 상태에서는 ‘회사’가 제공하는 ‘더벤티 멤버십’의 모든 서비스를 제공받을 수 있습니다.
              </li>
              <li>
                3. 휴면회원 : 1년간 로그인 기록이나, 서비스 이용기록이 없는 ‘회원’은 관련 법령에 따라 휴면회원으로
                자동전환되며, ‘회사’가 정하는 별도의 절차를 통해서만 서비스를 다시 이용할 수 있습니다. 단, ‘비회원’의
                경우에는 휴면회원 전환 대상에서 제외되며 관련 법령에 따라 자동탈퇴 및 파기됩니다.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>제 20 조 (‘스탬프’ 적립 및 ‘쿠폰’의 발행과 사용)</h1>
        <p>
          ‘회원’은 다음 각 호와 같이 ‘더벤티 멤버십’을 통해 ‘스탬프’를 적립함으로써, ‘쿠폰’을 발행하고 사용할 수
          있습니다.
        </p>
        <ul>
          <li>
            1. ‘매장’에서 일부 음료를 제외한 제조음료 한 잔당 한 개의 ‘스탬프’가 발행되며, ‘스탬프’ 10개 적립 시 ‘아이스
            아메리카노 무료교환권(이하 ‘스탬프 쿠폰’이라고 함)’이 발행됩니다. ‘비회원’의 경우에는 ‘회원’이 되어야만
            ‘스탬프 쿠폰’을 사용할 수 있습니다.
          </li>
          <li>
            2. 제1호 단서의 ‘스탬프 쿠폰’을 활성화하기 위한 절차는 최초 적립한 ‘스탬프’의 유효기간이 경과하기 전에
            하여야 하며, ‘스탬프 쿠폰’ 발행 익일부터 사용이 가능합니다.
          </li>
          <li>3. ‘스탬프 쿠폰’을 사용하여 ‘매장’의 제조음료를 제공받는 경우에는 ‘스탬프’가 적립되지 않습니다.</li>
          <li>
            4. ‘스탬프’의 유효기간은 적립일 포함 180일이며 180일이 경과한 ‘스탬프’는 소멸됩니다. ‘회원’은 180일 내에
            ‘스탬프’ 10개를 적립하면 ‘스탬프 쿠폰’이 발행되며, 유효기간이 지난 ‘스탬프’는 유효기간 연장 및 재발행이
            불가합니다.
          </li>
          <li>
            5. ‘스탬프 쿠폰’의 유효기간은 발행일 포함 30일이며, 30일이 경과한 ‘스탬프 쿠폰’은 소멸됩니다. ‘회원’은
            30일간 ‘스탬프 쿠폰’을 사용하여 음료를 교환해야 하며, 유효기간이 지난 ‘스탬프 쿠폰’은 유효기간 연장 및
            재발행이 불가합니다. 단, ‘이벤트 쿠폰’의 경우 유효기간이 상이할 수 있습니다.
          </li>
          <li>
            6. ‘스탬프’ 적립은 ‘회원’이 등록한 휴대전화번호 또는 ‘더벤티 멤버십’ ‘앱’으로 가능하며, ‘스탬프’ 적립 및
            ‘쿠폰’ 사용 내역은 ‘앱’에서 확인 가능합니다.
          </li>
          <li>
            7. ‘스탬프’ 적립이 가능한 음료를 구매하였으나 ‘스탬프’ 적립을 하지 못하였을 경우에는 영수증을 지참하여
            ‘결제’한 ‘매장’에 ‘결제’시간 포함 48시간 이내에 방문하면 ‘스탬프’ 적립이 가능합니다.
          </li>
          <li>
            8. 모든 ’쿠폰’은 동일 가격 이상의 타 음료로 교환 가능하나, 교환하고자 하는 음료가 더 높은 금액의 음료일
            경우에는 차액을 부담해야 하며, 낮은 금액의 음료를 교환한다 하더라도 차액을 환불하지 않습니다(가격 할인행사로
            인한 경우에도 동일합니다). 단, 이 약관에 따라 환불이 되는 경우에는 그러하지 않습니다.
          </li>
          <li>9. 모든 ‘쿠폰’은 다른 ‘쿠폰’과 합산하여 사용할 수 없습니다.</li>
          <li>10. ‘쿠폰’은 현금으로 반환되지 않으며, 타인에게 양도할 수 없습니다.</li>
          <li>11. ‘쿠폰’으로 다른 상품권이나 기타 결제가 가능한 유가증권을 교환하거나 구입할 수 없습니다.</li>
          <li>12. 제12조 제1항 각 호의 경우 ‘회사’는 ‘스탬프’ 및 ‘쿠폰’의 이용을 제한할 수 있습니다.</li>
          <li>
            14. 전 11호 내지 12호의 사항 기타 ‘스탬프’ 및 ‘쿠폰’과 관련된 내용은 ‘회사’의 정책 기타 사정에 따라 수시
            변동될 수 있으며, 구체적인 사항은 ‘더벤티 멤버십’ ‘앱’에서 확인하시기 바랍니다.
          </li>
          <li>15. '쿠폰'은 '앱' 화면의 '쿠폰' 바코드를 '매장'에 제시하여 사용하여야 합니다.</li>
        </ul>
      </li>
      <li>
        <h1>제 21 조 (‘교환권’ 및 ‘금액권’ 구매 등)</h1>
        <ul>
          <li>① ‘교환권’과 ‘금액권’은 ‘회원’만 구매 및 선물할 수 있습니다.</li>
          <li>
            ② ‘교환권’과 ‘금액권’을 구매하는 ‘회원’이 미성년자인 경우, 법정대리인의 동의를 얻지 못하면 미성년자 본인
            또는 법정대리인이 계약을 취소할 수 있으며, 이에 따라 ‘회사’는 미성년자인 ‘회원’의 구매시 법정대리인의 동의
            여부를 확인하거나 구매를 제한할 수 있습니다.
          </li>
          <li>
            ③ ‘회원’이 ‘교환권’과 ‘금액권’을 구매 요청하는 경우, ‘회사’는 그에 대한 수신확인통지를 하며, 그 통지가
            ‘회원’에게 도달된 때에 계약이 체결된 것으로 봅니다. 만약 ‘회원’이 수신확인통지를 받은 후 해당 내용에
            불일치가 있는 경우에는 ‘회사’에게 그 변경 및 취소를 요청할 수 있고, ‘회사’는 지체 없이 그 요청에 따라
            처리합니다. 다만 이미 결제를 한 경우에는 제26조에 따라 구매취소를 할 수 있습니다.
          </li>
          <li>
            ④ ‘교환권’과 ‘금액권’의 구매가격은 다음 각 호와 같습니다.
            <ul>
              <li>1. ‘금액권’은 5천원, 1만원, 2만원, 3만원, 5만원으로 구성되어 있습니다.</li>
              <li>2. ‘교환권’은 구매하고자 하는 제품별로 쿠폰의 가격이 결정됩니다.</li>
            </ul>
          </li>
          <li>
            ⑤ ‘교환권’과 ‘금액권’ 구매 시 결제수단은 다음 각 호와 같습니다.
            <ul>
              <li>1. ‘교환권’과 ‘금액권’은 신용(체크)카드, 휴대폰 소액결제, 간편결제를 통해 결제할 수 있습니다.</li>

              <li>2. 제1호의 결제수단은 ‘회사’의 정책에 따라 변경될 수 있습니다.</li>
              <li>
                3. ‘교환권’과 ‘금액권’ 구매 시 현금영수증은 발행이 불가하며, 해당 ‘교환권’과 ‘금액권’을 사용하여
                ‘상품’을 구매할 경우 해당 구매금액만큼의 현금영수증 발행이 가능합니다.
              </li>
            </ul>
          </li>
          <li>
            ⑥ ‘교환권’과 ‘금액권’의 유효기간은 다음 각 호와 같습니다.
            <ul>
              <li>
                1. ‘금액권’의 유효기간은 최초 구매일 포함 366일(1년)이며, 유효기간 내 연장 신청 시 93일(3개월)씩 연장이
                가능합니다. 최초 구매일자로부터 5년 경과 시 소멸시효기한이 도래되어 해당 금액권이 소멸됩니다.
              </li>
              <li>
                2. ‘교환권’의 유효기간은 구매일 포함 93일(3개월)이며, 유효기간 내 연장 신청 시 93일(3개월)씩 연장이
                가능합니다. 최초 구매일자로부터 5년 경과 시 소멸시효기간이 도래되어 해당 쿠폰이 소멸됩니다.
              </li>
              <li>
                3. 제22조 ‘선물하기’를 통해 ‘수신자’에게 발송된 ‘교환권’ 및 ‘금액권’의 유효기간은 ‘수신자’의
                수신일로부터 기산합니다.
              </li>
              <li>
                4. 이벤트로 ‘회사’에서 발행한 ‘교환권’ 또는 ‘금액권’은 제1 내지 2호에서 정한 유효기간과 다를 수 있으며,
                환불, 통합, 유효기간 연장이 불가합니다.
              </li>
              <li>
                5. 유효기간 도래 전 ’회사’는 푸쉬 알림 및 문자메시지 등의 수단을 이용하여 ‘회원’에게 ‘교환권’ 및
                ‘금액권’의 유효기간 만료 안내를 만료 7일/3일/1일 이전에 사전 통지합니다.
              </li>
              <li>
                6. 유효기간 도래 전 유효기간 연장신청을 하였음에도 불구하고 ’회사’의 사정으로 연장을 처리할 수 없는
                경우에는 해당 ‘교환권’ 혹은 ‘금액권’의 구매금액 전액을 환불합니다.
              </li>
            </ul>
          </li>
          <li>
            ⑦ ‘교환권’과 ‘금액권’의 이용조건은 다음 각 호와 같습니다.
            <ul>
              <li>
                1. ‘금액권’은 모바일 상품권의 형태로, ‘회원’이 이용할 수 있는 금액은 구매한 금액과 동일하며, 만약
                ‘상품’을 구매하고자 할 때 ‘금액권’의 잔액이 부족한 경우 다른 결제수단(현금, 카드, 쿠폰 등)과 복합결제가
                가능합니다.
              </li>
              <li>
                2. ‘교환권’은 물품제공형 모바일 쿠폰의 형태로, 수신자는 ‘매장’에서 해당 ‘교환권’에 표기된 ‘상품’과
                동일한 ‘상품’을 교환할 수 있습니다. 다만 수신자는 해당 ‘상품’과 동일한 금액의 다른 ‘상품’으로 교환할 수
                있으며, 해당 금액을 초과하는 ‘상품’으로 교환하는 경우 다른 결제수단(현금, 카드, 금액권 등)을 통해
                초과되는 금액을 지불하여 사용할 수 있습니다.
              </li>
              <li>
                3. ‘회원’은 ‘매장’에서 판매하는 ‘상품’ 등에 대하여 가격 할인기간을 포함하여 언제든지 ‘교환권’과
                ‘금액권’을 사용할 수 있습니다. 다만, ‘교환권’ 및 ‘금액권’으로 모바일 상품권 등 기타 유가증권을 구매할 수
                없으며, ‘회사’가 사전에 ‘앱’에 고지한 모바일 상품권 사용 불가 매장 또는 특정 상품 등에 대하여는 사용이
                제한될 수 있습니다.
              </li>
              <li>
                4. ‘회원’이 ‘매장’에서 ‘교환권’ 혹은 ‘금액권’으로 ‘상품’ 구입시 ‘스탬프’를 적립 받을 수 있습니다. 다만
                ‘스탬프’ 적립 대상이 아닌 ‘상품’이나 ‘매장’의 경우와 이벤트로 ‘회사’에서 발행한 ‘교환권’ 또는
                ‘금액권’으로 구매하는 경우는 적립이 불가합니다.
              </li>
              <li>
                5. ‘회원’이 보유하고 있던 ‘교환권’ 및 ‘금액권’을 삭제하고자 하는 경우에는 제27조에 따라 환불절차를
                거쳐야 합니다.
              </li>
              <li>6. '교환권'과 ‘금액권’은 '앱' 화면의 바코드를 '매장'에 제시하여 사용하여야 합니다.</li>
            </ul>
          </li>
          <li>
            ⑧ ‘회사’는 다음 각 호에 해당하는 경우 ‘교환권’ 또는 ‘금액권’의 전부 또는 일부에 대해 사용을 제한하거나
            중지시킬 수 있습니다.
            <ul>
              <li>
                1. ‘회사’가 제공하는 방식이 아닌 불법 또는 부정한 방법으로 ‘교환권’이나 ‘금액권’을 구매하거나 등록하여
                사용하고자 하는 경우
              </li>
              <li>2. 타인의 명의를 도용한 결제수단을 통하여 송신된 ‘교환권’이나 ‘금액권’을 사용하고자 하는 경우</li>
              <li>3. 제12조 제1항 각 호의 경우</li>
              <li>4. 기타 천재지변, 국가비상사태 등 불가항력적 사유가 있는 경우</li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>제 22 조 (선물하기 및 거절)</h1>
        <ul>
          <li>
            ① ‘회원’은 ‘앱’을 통해 ‘교환권’이나 ‘금액권’을 자신이 원하는 대상에게 선물을 할 수 있습니다(이하
            ‘선물하기’라 합니다). 단, 자신이 ‘선물하기’를 통해 받은 ‘교환권’이나 ‘금액권’은 또다시 ‘선물하기’를 할 수
            없습니다.
          </li>
          <li>
            ② ‘회원’은 자신의 휴대전화에 등록된 연락처를 선택하여 ‘선물하기’의 대상(이하 ‘수신자’라 합니다)을 지정할 수
            있습니다.
          </li>
          <li>
            ③ 선물은 다음 각 호의 형태로 전송됩니다.
            <ul>
              <li>
                1. ‘수신자’의 ‘더벤티 멤버십’ ‘회원’ 여부와 무관하게 ‘수신자’의 휴대전화번호로 문자메시지 형태로
                발송됩니다.
              </li>
            </ul>
          </li>
          <li>
            ④ 전항 제2호의 경우, 번호도용 문자차단 서비스나 휴대폰 번호 도용방지 서비스에 가입된 휴대폰은 송∙ 수신이
            불가할 수 있으며, 기타 ‘수신자’로 지정한 고객의 단말기 설정에 따라 문자메시지 수신이 불가능한 경우 ‘교환권’
            및 ‘금액권’을 받지 못할 수 있습니다. 이 경우 이로 인해 발생된 문제에 대해서는 ‘회사’가 책임지지 않습니다.
          </li>
          <li>
            ⑤ ‘수신자’의 지정은 ‘선물하기’ 기능을 이용하는 ‘회원’ 본인의 책임하에 이루어지며, ‘수신자’의 오지정,
            휴대전화번호 오류 등으로 인하여 발생된 문제에 대해 ‘회사’는 책임을 지지 않습니다.
          </li>
          <li>
            ⑥ 선물한 ‘교환권’ 및 ‘금액권’은 동일한 휴대폰 번호로 문자메시지 1회 재전송이 가능하며, 모바일 상품권
            고객센터를 통해서만 가능합니다.
          </li>
          <li>⑦ ‘수신자’가 수령한 ‘교환권’ 및 ‘금액권’은 ‘매장’에 제시하여 ‘상품’을 교환 혹은 구매할 수 있습니다.</li>
          <li>
            ⑧ ‘회원’은 ‘선물하기’ 서비스를 상업적으로 이용할 수 없으며, ‘더벤티 멤버십’ 서비스에서 제공된 ‘선물하기’
            기능이 아닌 다른 방식으로 전달된 ‘교환권’ 혹은 ‘금액권’의 이용으로 인해 발생된 문제에 대해서는 ‘회사’는
            책임을 지지 않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 23조 (소멸시효)</h1>
        <ul>
          <li>
            ① ‘교환권’ 및 ‘금액권’을 구매한 날로부터 5년이 경과하면 상법 상의 상사채권소멸시효가 완성되어 ‘회원’은 물품
            등의 제공, 환불 및 잔액반환을 요청할 수 없습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 24 조 (구매취소)</h1>
        <ul>
          <li>
            ① ‘교환권’ 및 ‘금액권’을 구매한 ‘회원’은 유효기간 내 취소요청 시 결제한 금액 전부를 환불받을 수 있습니다.
            단, 다음 각 호의 경우에는 구매취소가 불가합니다.
            <ul>
              <li>
                1. 구매자 혹은 ‘선물하기’를 한 이후 ‘수신자’가 해당 ‘교환권’이나 ‘금액권’를 사용한 경우(‘상품’을 교환
                또는 구매한 경우를 말합니다)
              </li>
            </ul>
          </li>
          <li>
            ① 전항에 따라 구매취소를 한 경우, 환불은 구매 시 결제한 수단에서 제공되는 환불절차 및 약관에 따라
            이루어집니다.
          </li>
          <li>
            ② 결제수단이 휴대폰 소액결제이고, 취소요청일이 구매한 날이 속한 월의 익월인 경우에는 ‘회사’는 ‘회원’이
            지정한 환불계좌를 통해 별도로 환불처리를 합니다.
          </li>
          <li>③ ‘수신자’는 전항에 따른 구매취소를 할 수 없고, 구매한 ‘회원’에게 전액 환불됩니다.</li>
        </ul>
      </li>
      <li>
        <h1>제 25 조 (환불)</h1>
        <ul>
          <li>
            ① ‘회원’이 ‘금액권’ 잔액의 환불을 요청하는 때에는 다음 각 호에 의거하여 환불 처리를 합니다.
            <ul>
              <li>
                1. 제21조 제4항에 따른 유효기간이 만료되지 않은 경우, 최종 시점의 잔액을 기준으로 100% 이상 사용 시 남은
                잔액을 환불 대상 금액으로 합니다.
              </li>
              <li>2. 제21조 제4항에 따른 유효기간이 만료된 경우, 잔액의 90%에 해당하는 금액을 환불처리 합니다.</li>
              <li>3. 소멸시효기한(5년) 도래 시 해당 카드가 소멸되며 환불이 불가합니다.</li>
            </ul>
          </li>
          <li>
            ② ‘회원’이 미사용 ‘교환권’의 환불을 요청하는 때에는 다음 각 호에 의거하여 환불 처리를 합니다.
            <ul>
              <li>1. 유효기간이 만료되지 않은 경우 100% 환불이 가능합니다.</li>
              <li>2. 유효기간 만료시 또는 소멸시효 기간 도래시의 경우 제1항 제2호 내지 3호를 준용합니다.</li>
            </ul>
          </li>
          <li>
            ③ 본 조에 따른 환불신청은 ‘교환권’이나 ‘금액권’의 구매자(‘선물하기’의 경우 최초 ‘선물한 자’)가 할 수
            있습니다.
          </li>
          <li>④ 본 조에 따른 환불신청은 선물하기 고객센터(1599-3785) 를 통해 환불 가능합니다.</li>
          <li>
            ⑤ 본 조에 따른 환불 신청 시, ‘회사’는 환불신청자의 본인확인 절차를 거친 후 해당 신청자가 지정한 계좌로
            신청일 기준 7영업일 이내에 환불금을 입금해 드립니다. 이 경우 본인의 환불 계좌를 잘못 지정하는 경우에는 확인
            절차 등으로 인해 환불이 다소 지연될 수 있습니다.
          </li>
          <li>⑤ 프로모션 또는 이벤트 목적으로 ‘회사’가 발행한 ‘교환권’ 및 ‘금액권’의 경우 환불이 불가합니다.</li>
        </ul>
      </li>
      <li>
        <h1>제 26 조 (책임 소재)</h1>
        <ul>
          <li>
            ① ‘회사’는 ‘회원’이 ‘교환권’ 및 ‘금액권’의 ‘선물하기’ 서비스 이용 중 발생한 손해에 대하여 배상할 책임을
            집니다. 단, 본 약관에 별도 명시된 경우 혹은 다음 각호에 해당하는 경우는 ‘회사’가 책임을 부담하지 않습니다.
            <ul>
              <li>
                1. ‘교환권’ 및 ‘금액권’을 ‘회사’가 지정하지 아니한 방법으로 불법 거래 또는 위/변조 등 ‘부정이용’된 경우
              </li>
              <li>2. ‘교환권’ 및 ‘금액권’을 도난 당하거나 분실한 경우</li>
              <li>
                3. 제3자가 권한 없이 ‘회원’의 ‘교환권’ 및 ‘금액권’을 이용할 수 있음을 알았거나 쉽게 알 수 있었음에도
                불구하고 ‘회원’이 자신의 ‘교환권’ 및 ‘금액권’ 정보(PIN 번호 포함)를 누설 또는 노출하거나 방치한 경우
              </li>
              <li>4. 기타 ‘회원’의 고의∙과실로 인하여 오전송 되거나 ‘부정이용’된 경우.</li>
            </ul>
          </li>
          <li>
            ② 회원이 본 조 제1항에서 규정한 행위를 하여 ‘회사’에 손해가 발생하는 경우, 해당 ‘회원’은 ‘회사’의 손해에
            대하여 배상할 책임을 집니다.
          </li>
          <li>
            ③ ‘회사’는 천재지변 등 이에 준하는 불가항력으로 인하여 ‘더벤티 멤버십’ 서비스를 제공할 수 없거나 ‘교환권’
            또는 ‘금액권’을 사용할 수 없는 경우, 기타 ‘회사’의 귀책사유가 없는 경우에는 이에 대한 책임을 지지 않습니다.
            또한 ‘회사’는 서비스 이용과 관련하여 ‘회원’의 고의 또는 과실로 인하여 발생한 손해에 관하여도 책임을 지지
            않습니다.
          </li>
        </ul>
      </li>
      <li>
        <h1>제 27 조 (‘더벤티 오더’ 서비스의 내용)</h1>
        <p>‘회사’는 다음 각 호와 같은 형태의 ‘더벤티 오더’ 서비스를 제공합니다.</p>
        <ul>
          <li>
            1. ’앱’ 통한 주문 및 결제 서비스
            <ul>
              <li>
                가) ’앱’을 통하여 ‘회원’과 ’회사(‘매장’을 포함하며, 이하 이 장에서 같습니다)’ 간에 ’상품’ 매매가
                이루어질 수 있도록 온라인 거래 서비스를 제공합니다.
              </li>
              <li>
                나) 나의 위치정보(GPS)를 기반으로 반경 1.5km 내 ‘매장’의 주문 가능 메뉴를 실시간으로 확인하여 온라인으로
                주문을 할 수 있습니다.
              </li>
            </ul>
          </li>
        </ul>
      </li>
      <li>
        <h1>제 28 조 (‘더벤티 오더’ 서비스 이용)</h1>
        <ul>
          <li>① ‘더벤티 오더’ 서비스는 ‘회원’에게만 제공됩니다.</li>
          <li>
            ② ‘더벤티 오더’ 서비스의 제공은 연중무휴를 원칙으로 합니다. 다만 ‘매장’의 운영시간과 동일하게 제공됩니다.
            ‘매장’ 별 운영시간이 상이하므로 해당 매장의 운영 시간을 미리 확인 후 서비스를 이용하여야 합니다.
          </li>
          <li>
            ③ ‘더벤티 오더’로 주문을 한 이후라도, 해당 ‘상품’의 원부재료 품절 또는 기기고장, 기타 해당 ‘매장’의 사유
            등으로 인하여 주문이 취소될 수 있습니다. 이 경우 ‘매장’에서 해당 사유가 포함된 주문 취소 내용이 푸쉬
            알림되며, ‘회원’은 ‘더벤티 오더’ 서비스의 [주문 내역 확인] 페이지에서 확인할 수 있습니다.
          </li>
          <li>
            ⑤ ‘더벤티 오더’로 주문을 한 이후 ‘매장’은 해당 주문을 접수합니다. 만약 ‘매장’이 7분간 접수를 하지 않을 경우
            주문 및 결제는 자동으로 취소됩니다.
          </li>
          <li>⑥ 일부 ‘매장’에서는 ‘더벤티 오더’를 통한 주문이 불가하며, 구체적인 내용은 ‘앱’에 공지합니다.</li>
        </ul>
      </li>
      <li>
        <h1>제 29 조 (결제 및 주문 취소 등)</h1>
        <ul>
          <li>
            ① ‘회원’은 ‘더벤티 오더’ 서비스 내 ‘상품’을 선택하여 주문하기를 함으로써 구매를 신청하며, 이에 따른 ‘회원’의
            결제 의사표시에 대해 ‘회사’가 주문 승인을 통해 승낙의 의사표시를 함으로써 ‘회사’와 회원 사이에 메뉴 주문에
            대한 매매계약이 성립합니다.
          </li>
          <li>
            ② ‘회사’는 전항의 구매신청에 대해 다음 각 호에 해당하면 승낙하지 않을 수 있습니다.
            <ul>
              <li>1. ‘더벤티 오더’로 주문이 불가능한 ‘상품'이거나 ‘매장’을 선택한 경우</li>
              <li>2. 신청 내용에 허위, 기재누락, 오기가 있는 경우</li>
              <li>
                3. 상행위(재판매)를 목적으로 구매하는 거래이거나, 거래 정황상 상행위(재판매)를 목적으로 한 구매라고
                판단되는 경우
              </li>
              <li>4. 구매 신청을 승낙하는 것이 회사의 기술상 현저히 지장이 있다고 판단되는 경우</li>
              <li>5. 미성년자의 구매신청으로서 법정대리인의 동의가 없는 경우</li>
              <li>6. 기타 ‘회원’의 귀책사유로 승인이 불가능하다고 판단되는 경우</li>
            </ul>
          </li>
          <li>
            ③ ‘회원’은 제1항 ‘회사’의 수신확인통지를 받은 경우 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은
            즉시 구매신청 변경 및 취소를 요청할 수 있고, ‘회사’는 가능한 해당 요청을 수용합니다. 다만, 결제를 한
            이후에는 본조 제7항에 따라 구매변경 및 결제취소가 불가할 수 있습니다.
          </li>
          <li>
            ④ ‘더벤티 오더’ 서비스의 결제에 따른 대금 지급 방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다.
            <ul>
              <li>1. 신용/체크카드</li>
              <li>2. 간편결제</li>
              <li>3. ‘교환권 및 ‘금액권’</li>
              <li>4. 쿠폰함에 등록된 ‘쿠폰’</li>
              <li>5. 휴대폰 소액결제</li>
            </ul>
          </li>
          <li>
            ⑤ ‘회원’은 전항에 따른 결제시 반드시 본인 명의의 결제 수단을 이용하여야 하며, 타인의 결제 수단을 임의로
            이용하여서는 안됩니다. 타인의 결제 수단을 임의 이용함으로써 발생하는 ‘회사’, 결제 수단의 적법한 소유자, 결제
            관련 제3자의 손실과 손해에 대한 책임은 ‘회원’에게 있습니다.
          </li>
          <li>⑥ ‘회사’는 ‘상품’ 등의 결제취소 및 환불과 관련하여 전자상거래법 등 관계법령을 준수합니다.</li>
          <li>
            ⑦ ‘회원’의 ‘더벤티 오더’ 주문에 따라 개별적으로 제조되는 ‘상품’의 경우 ‘매장’ 접수 이후에는 전자상거래법 등
            관계법령에 따라 결제취소가 제한될 수 있습니다. ‘회사’는 결제취소가 제한된다는 사실을 결제 화면 등에서
            고지합니다.
          </li>
          <li>
            ⑧ 결제 이후 결제수단의 변경은 ‘상품’을 구매한 당해 ‘매장’에서 구매취소 및 재결제 절차를 통해 가능하며,
            반드시 해당 영수증을 소지하고 결제일 포함 3일 이내에 해당 ‘매장’에 방문하여야 합니다.
          </li>
          <li>
            ⑨ ‘회원’이 ‘더벤티 오더’를 통해 주문한 ‘상품’이 제조완료된 경우 해당 ‘매장’은 ‘회원’에게 푸쉬 알림을 통해
            제조완료 및 픽업요청을 통지합니다. ‘매장’은 최초 픽업요청 시점으로부터 1시간 까지 ‘상품’을 보관하며 이후
            폐기합니다. ‘회원’이 ‘매장’의 제조완료 및 픽업요청에도 불구하고 ‘상품’을 미 수령할 경우 ‘상품’에 대한 재제공
            혹은 환불(결제취소)은 받아들여지지 아니할 수 있으며 이로 인하여 ‘회원’에게 발생하는 손해에 대하여 ’회사’는
            고의 또는 과실이 없는 한 책임을 부담하지 않습니다.
          </li>
        </ul>
      </li>
    </ul>
  </div>
);
