import React, { useState } from 'react';
import classNames from 'classnames/bind';
import useMountEffect from '@restart/hooks/useMountEffect';
import moment from 'moment';

import styles from '../common.module.scss';
import doc20220620 from './docs/20220620';
import doc20231106 from './docs/20231106';

const cx = classNames.bind(styles);

const DOCS = {
  20220620: doc20220620,
  20231106: doc20231106,
};

const Page = () => {
  const [currentDoc, setCurrentDoc] = useState();

  useMountEffect(() => {
    const defaultDate = Object.keys(DOCS).at(-1);
    setCurrentDoc(DOCS[defaultDate]);
  });

  const onChangeItem = (e) => {
    setCurrentDoc(DOCS[e.target.value]);
  };

  return (
    <div className={cx('container')}>
      <select onChange={onChangeItem}>
        {Object.keys(DOCS)
          .sort((a, b) => (a < b ? 1 : -1))
          .map((key) => (
            <option key={key} value={key}>
              {moment(key, 'YYYYMMDD').format('YYYY.MM.DD 시행안')}
            </option>
          ))}
      </select>
      <div className={cx('doc')}>{currentDoc}</div>
    </div>
  );
};

export default Page;
