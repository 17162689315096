import React from 'react';
import classNames from 'classnames/bind';
import { useParams, useSearchParams } from 'react-router-dom';
import useMountEffect from '@restart/hooks/useMountEffect';

import styles from './index.module.scss';

const cx = classNames.bind(styles);

const Page = () => {
  const params = useParams();
  const [searchParams] = useSearchParams();

  useMountEffect(() => {
    executeApp();
    setTimeout(moveToDownload, 2000);
  });

  const moveToDownload = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      moveToAppStore();
    } else if (/android/i.test(userAgent)) {
      moveToPlayStore();
    }
  };

  const moveToAppStore = (e) => {
    window.location.replace('itms-apps://apps.apple.com/us/app/더벤티-더벤티-멤버십/id1631064511');
  };

  const moveToPlayStore = (e) => {
    window.location.replace('market://details?id=kr.co.theventi');
  };

  const executeApp = () => {
    const param = params['*'];

    // 쿠폰 등록
    if (param.startsWith('coupon')) {
      window.location.replace(`theventi://coupon/add?${searchParams.toString()}`);
    }
    // 이벤트 상세
    else if (param.startsWith('event/detail')) {
      window.location.replace(`theventi://event/detail?${searchParams.toString()}`);
    }
    // 예약매장 내역
    else if (param.startsWith('reserve/shops')) {
      window.location.replace('theventi://order/reserve/shops');
    }
    // 픽업매장 내역
    else if (param.startsWith('pickup/shops')) {
      window.location.replace('theventi://order/pickup/shops');
    }
    // 픽업 오더
    else if (param.startsWith('pickup')) {
      window.location.replace(`theventi://order/pickup?${searchParams.toString()}`);
    }
  };

  return (
    <div className={cx('container')}>
      <img
        src="https://play-lh.googleusercontent.com/9P2z1KR_JOUbHOS2mFe8uzrG3D_NLT8zLVekppkCUXLFcO4wITxezSGcRNQl2AtHYBw=w240-h480-rw"
        className={cx('icon')}
        alt=""
      />
      <h1>더벤티 - 더벤티 멤버십</h1>
      <p>
        지금 더벤티 멤버십 앱을 다운로드 하시고,
        <br />
        다양한 멤버십 혜택의 기회를 잡으세요!
      </p>
      <div className={cx('buttons')}>
        <img
          className={cx('button')}
          src={require('../../assets/images/appstore.png')}
          onClick={moveToAppStore}
          alt=""
        />
        <img
          className={cx('button')}
          src={require('../../assets/images/playstore.png')}
          onClick={moveToPlayStore}
          alt=""
        />
      </div>
    </div>
  );
};

export default Page;
